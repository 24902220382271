import { __ } from "@wordpress/i18n";
import { useState, useEffect } from "@wordpress/element";
const _ = require("lodash");
import {
	findFilterText,
	findImageByType,
	getAdTypes,
	numberToLocaleString,
} from "../utils";

export default function Block({
	view,
	tradeType,
	backgroundColor,
	borderColor,
	borderThickness,
	borderRadius,
	cardsPerRow,
	cardsGridGap,
	usersCanChangeView,
	padding,
	showType,
	showAltType,
	showArea,
	showIntro,
	showBait,
	showFloor,
	showTradeType,
	showAvailability,
	showRent,
	showPrice,
	showStreetAddress,
	inEditor,
	ctaText,
	blockTitle,
	blockTitleNoAds,
	imageSource,
	baitBackgroundColor,
	baitTextColor,
	baitBorderRadius,
	imageSourceIndex,
}) {
	const [blockView, setBlockView] = useState(view);

	const LinkTag = inEditor ? "div" : "a";

	/**
	 * Save the block element to localStorage when clicking an ad to scroll the window to the top of the block when returning
	 */
	function saveBlockToLocalStorage() {
		const block = document.querySelector(
			".wp-block-goland-features-goland-blocks-single-property-ads"
		);
		if (block) {
			localStorage.setItem(
				"goland-blocks-single-property-ads",
				block.offsetTop
			);
		}
	}

	/**
	 * Set the block view to the one set by the user
	 */
	useEffect(() => {
		setBlockView(view);
		if (
			localStorage.getItem("goland-blocks-single-property-ads") &&
			!inEditor
		) {
			window.scrollTo(
				0,
				localStorage.getItem("goland-blocks-single-property-ads")
			);
			localStorage.removeItem("goland-blocks-single-property-ads");
		}
	}, [view]);

	/**
	 * Set the height of the headers to be the same in the card view
	 */
	useEffect(() => {
		if (blockView === "cards") {
			var maxHeight = 0;

			setTimeout(() => {
				// Find the tallest header
				jQuery(".single-card .header-container").each(function () {
					if (jQuery(this).height() > maxHeight) {
						maxHeight = jQuery(this).height();
					}
				});

				// Set all headers to the height of the tallest header
				jQuery(".single-card .header-container").height(maxHeight);
			}, 0);
		} else {
			jQuery(".single-list-item").height("auto");
		}
	}, [blockView]);

	const property = goland.property || {};
	const filters = goland.filters || {};
	let property_ads = property.ads || {};

	// Sort ads by area (descending)
	let ads = _.orderBy(property_ads, [(ad) => ad.actual_area], ["desc"]);

	const cardStyles = {
		backgroundColor,
		borderColor,
		border: `solid ${borderThickness}px ${borderColor}`,
		borderRadius: `${borderRadius}px`,
		padding: `${padding}px`,
	};
	const cardsWrapperStyles = {
		gap: `${cardsGridGap}px`,
		gridTemplateColumns: `repeat(${cardsPerRow}, minmax(0, 1fr))`,
	};

	const cardsWrapperCss = `
		.cards-wrapper {
			@media screen and (max-width: 1200px) {
				grid-template-columns: repeat(${cardsPerRow >= 3 ? '3' : cardsPerRow}, 1fr) !important;
			}
	
			@media screen and (max-width: 900px) {
				grid-template-columns: repeat(${cardsPerRow >= 2 ? '2' : cardsPerRow}, 1fr) !important;
			}
	
			@media screen and (max-width: 768px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
	`;

	const baitCss = `
		.bait {
			background-color: ${baitBackgroundColor} !important;
			color: ${baitTextColor} !important;
			border-radius: ${baitBorderRadius}px !important;
		}
	`;
	// Filter ads by trade type set by the user
	if (tradeType) {
		ads = ads.filter((ad) => {
			if (tradeType === "for_sale_rent") return ad;
			if (tradeType === "for_sale" && ad.sales_method == "for_sale") return ad;
			if (tradeType === "for_rent" && ad.sales_method == "for_rent") return ad;
		});
	}

	const cards = ads.map((ad) => {
		const imageObject = findImageByType(ad.images, imageSource, "medium", false, imageSourceIndex, true);
		const imageSrc = imageObject.url;
		const tradeTypes = [];

		if (ad.sales_method == "for_rent")
			tradeTypes.push(findFilterText(filters, "sales_methods", "for_rent"));
		if (ad.sales_method == "for_sale")
			tradeTypes.push(findFilterText(filters, "sales_methods", "for_sale"));
		const typesObj = getAdTypes(ad);

		return (
			<LinkTag
				className="single-card"
				href={`${goland.pages.ad.template}${ad.slug}`}
				key={ad.id}
				style={cardStyles}
				onClick={saveBlockToLocalStorage}
			>
				<div className="header-container">
					{showTradeType && (
						<p className="prem-trade-types">{tradeTypes.join(", ")}</p>
					)}
					{(showType || showAltType) && (
						<p className="prem-type">
							{showType && typesObj.main && <span>{typesObj.main}</span>}{" "}
							{showAltType && typesObj.other.length > 0 && (
								<span className="other-areas">
									{"(" + typesObj.other.join(", ") + ")"}
								</span>
							)}
						</p>
					)}
					{showArea && (
						<p className="prem-size">
							{numberToLocaleString(ad.actual_area)} m²
							{ad.sub_areas.length > 0 && (
								<span style={{ display: "block" }} className="sub-areas">
									({ad.sub_areas.join("/")} m²)
								</span>
							)}
						</p>
					)}
				</div>
				<div className="img-wrapper">
					<img src={imageSrc} />
					{ad.bait && showBait && (
						<div className="bait">
							<span>{ad.bait}</span>
						</div>
					)}
				</div>
				<div className="card-footer-wrapper">
					{showIntro && <p className="prem-intro">{ad.intro}</p>}
					<div className="prem-details">
						{showStreetAddress && <p>{ad.address.street_address}</p>}
						{showFloor && (
							<p>{`${__("Floor", "goland-features")}: ${findFilterText(
								filters,
								"floors",
								ad.floor
							)}`}</p>
						)}
						{showAvailability && (
							<p>
								{findFilterText(filters, "availabilities", ad.availability)}
							</p>
						)}
					</div>
					<div
						className="price-and-cta"
					>
						<div>
							{showRent && ad.rent_total && <p>{ad.rent_total} €/kk</p>}
							{ad.selling_price && showPrice && <p>{ad.selling_price} €</p>}
						</div>
						<button
							className="btn cta-button"
							style={{ margin: "0", cursor: "pointer" }}
						>
							{ctaText ? ctaText : __("More", "goland-features")}
						</button>
					</div>
				</div>
			</LinkTag>
		);
	});

	const listItems = ads.map((ad) => {
		const tradeTypes = [];
		const imageObject = findImageByType(ad.images, imageSource, "medium", false, imageSourceIndex, true);
		const imageSrc = imageObject.url;

		if (ad.sales_method == "for_rent")
			tradeTypes.push(findFilterText(filters, "sales_methods", "for_rent"));
		if (ad.sales_method == "for_sale")
			tradeTypes.push(findFilterText(filters, "sales_methods", "for_sale"));

		const prices = [];
		if (ad.rent_total) prices.push(`${ad.rent_total} €/kk`);
		if (ad.selling_price) prices.push(`${ad.selling_price} €`);

		const typesObj = getAdTypes(ad);

		return (
			<LinkTag
				href={`${goland.pages.ad.template}${ad.slug}`}
				key={ad.id}
				style={{ borderRadius: cardStyles.borderRadius }}
				onClick={saveBlockToLocalStorage}
			>
				<div
					className="single-list-item"
					style={{ ...cardStyles, overflow: "hidden" }}
				>
					<div className="img-wrapper">
						<img src={imageSrc} />
						{ad.bait && showBait && (
							<div className="bait">
								<span>{ad.bait}</span>
							</div>
						)}
					</div>
					<div className="left-container">
						{showTradeType && (
							<p className="prem-trade-types">{tradeTypes.join(", ")}</p>
						)}
						<div className="type-and-area">
							{(showType || showAltType) && (
								<p className="prem-type">
									{showType && typesObj.main && typesObj.main}
									{showAltType && typesObj.other.length > 0 && (
										<span className="other-areas">
											{"(" + typesObj.other.join(", ") + ")"}
										</span>
									)}
								</p>
							)}
							{showArea && (
								<p className="prem-size">
									{numberToLocaleString(ad.actual_area)} m²
									{ad.sub_areas.length > 0 && (
										<span style={{ display: "block" }} className="sub-areas">
											({ad.sub_areas.join("/")} m²)
										</span>
									)}
								</p>
							)}
						</div>
						{showIntro && <p className="prem-intro">{ad.intro}</p>}
						<div className="prem-details">
							<div className="texts">
								{showStreetAddress && <p>{ad.address.street_address}</p>}
								{showFloor && (
									<p>{`${__("Floor", "goland-features")}: ${findFilterText(
										filters,
										"floors",
										ad.floor
									)}`}</p>
								)}
								{showAvailability && (
									<p>
										{findFilterText(filters, "availabilities", ad.availability)}
									</p>
								)}
							</div>
							<button
								className="btn cta-button"
								style={{ margin: "0", cursor: "pointer" }}
							>
								{ctaText ? ctaText : __("More", "goland-features")}
							</button>
						</div>
						<p>
							{showRent && ad.rent_total && `${ad.rent_total} €/kk`}{" "}
							{showPrice && showRent && prices.length > 1 && "|"}{" "}
							{showPrice && ad.selling_price && `${ad.selling_price} €`}
						</p>
					</div>
				</div>
			</LinkTag>
		);
	});

	return (
		<>
			{blockTitle && <h3>{blockTitle}</h3>}
			{usersCanChangeView && ads.length > 0 && (
				<div className="view-type-switcher">
					<button
						onClick={() => {
							setBlockView("cards");
						}}
						className={blockView === "cards" ? "active" : ""}
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path d="M384 96V224H256V96H384zm0 192V416H256V288H384zM192 224H64V96H192V224zM64 288H192V416H64V288zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
						</svg>
					</button>
					<span className="separator"></span>
					<button
						onClick={() => setBlockView("list")}
						className={blockView === "list" ? "active" : ""}
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
						</svg>
					</button>
				</div>
			)}

			{ads.length === 0 ? (
				blockTitleNoAds && <p>{blockTitleNoAds}</p>
			) : blockView === "cards" ? (
				<div className="cards-wrapper" style={cardsWrapperStyles}>
					{cards}
				</div>
			) : (
				<div className="list-wrapper">{listItems}</div>
			)}
			<style>{cardsWrapperCss}</style>
			<style>{baitCss}</style>
		</>
	);
}
